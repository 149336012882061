import { useMemo } from 'react'
import { ScreenBreakpoints, useWindowSize } from '..'

type StackGridOptions = {
  // stackGridGutterWidth: number
  // stackGridGutterHeight: number
  // stackGridColumnWidth: string
  columnCount: number
}

const { breakpointMd, breakpointMl, breakpointLg } = ScreenBreakpoints
export const useResponsiveStackGridOptions = (): StackGridOptions => {
  const { width } = useWindowSize()

  if (!!width && width < breakpointMd)
    // mobile
    return {
      columnCount: 1,
    }

  if (!!width && width < breakpointLg)
    // md
    return {
      columnCount: 2,
    }

  // lg
  return {
    columnCount: 3,
  }
}

type KeenSliderOptions = {
  totalSlides?: number
  slidesPerPage: number
  isPerViewAuto?: boolean
  spacing: number
  height?: number
  duration?: number
  autoSlide?: boolean
  freezeDuration?: number
}

export type KeenSliderOptionTypes =
  | 'MoreFlats'
  | 'RecommendedFlats'
  | 'PropImageSlider'
  | 'HighlightRealEstate' //TODO: evaluate if still necessary
  | 'CurrentPressArticles'
  | 'BlockImageGalleryBig'
  | 'BlockImageGallerySmall'
  | 'WorkingForAccentro'
  | 'LexiconSlider'
  | 'FavoriteProperties'

export const useResponsiveKeenSliderOptions = (
  optionType: KeenSliderOptionTypes
): KeenSliderOptions => {
  const { width } = useWindowSize()

  return useMemo(() => {
    switch (optionType) {
      case 'MoreFlats':
        if (!!width && width < breakpointMd) {
          return {
            slidesPerPage: 1.2,
            spacing: 28,
          }
        }
        if (!!width && width < breakpointLg) {
          return {
            slidesPerPage: 2.4,
            spacing: 40,
          }
        }
        return {
          slidesPerPage: 3.3,
          spacing: 80,
        }
      case 'RecommendedFlats':
        if (!!width && width < breakpointMd) {
          return {
            slidesPerPage: 1,
            spacing: 24,
          }
        }
        if (!!width && width < breakpointMl) {
          return {
            slidesPerPage: 1.2,
            spacing: 48,
          }
        }
        if (!!width && width < breakpointLg) {
          return {
            slidesPerPage: 1.9,
            spacing: 48,
          }
        }
        return {
          slidesPerPage: 2.3,
          spacing: 48,
        }
      case 'FavoriteProperties':
        // FavoriteProperties slider is only shown in mobile view, only need to consider mobile view
        return {
          slidesPerPage: 1,
          spacing: 24,
        }

      case 'PropImageSlider':
        if (!!width && width < breakpointMd) {
          return {
            slidesPerPage: 1,
            spacing: 12,
          }
        }

        if (!!width && width < breakpointLg) {
          return {
            slidesPerPage: 1,
            spacing: 0,
          }
        }

        return {
          slidesPerPage: 1,
          spacing: 0,
        }

      case 'BlockImageGalleryBig':
        if (!!width && width < breakpointMd) {
          return {
            slidesPerPage: 1.2,
            spacing: 16,
            height: 190,
          }
        }

        if (!!width && width < breakpointMl) {
          return {
            slidesPerPage: 1.1,
            spacing: 16,
            height: 523,
          }
        }

        if (!!width && width < breakpointLg) {
          return {
            slidesPerPage: 1.2,
            spacing: 18,
            height: 702,
          }
        }

        return {
          slidesPerPage: 1.1,
          spacing: 24,
          height: 702,
        }

      case 'BlockImageGallerySmall':
        if (!!width && width < breakpointMd) {
          return {
            slidesPerPage: 1.2,
            spacing: 16,
            height: 190,
          }
        }

        if (!!width && width < breakpointMl) {
          return {
            slidesPerPage: 2.5,
            spacing: 16,
            height: 254,
          }
        }
        if (!!width && width < breakpointLg) {
          return {
            slidesPerPage: 2.9,
            spacing: 24,
            height: 262,
          }
        }

        return {
          slidesPerPage: 3,
          spacing: 24,
          height: 262,
        }

      case 'CurrentPressArticles':
        const pressOptions = {
          duration: 1000,
          autoSlide: true,
          freezeDuration: 5000,
        }
        if (!!width && width < breakpointMd) {
          return {
            slidesPerPage: 1,
            spacing: 24,
            ...pressOptions,
          }
        }

        return {
          slidesPerPage: 1,
          spacing: 64,
          ...pressOptions,
        }
      case 'LexiconSlider':
        return {
          slidesPerPage: 6,
          isPerViewAuto: true,
          spacing: 4,
        }
      case 'WorkingForAccentro':
        const workingOptions = {
          duration: 1000,
          autoSlide: true,
          freezeDuration: 10000,
        }
        if (!!width && width < breakpointMd) {
          return {
            slidesPerPage: 1,
            spacing: 24,
            ...workingOptions,
          }
        }

        return {
          slidesPerPage: 1,
          spacing: 64,
          ...workingOptions,
        }

      case 'HighlightRealEstate':
        if (!!width && width < breakpointMd) {
          return {
            slidesPerPage: 1,
            spacing: 12,
          }
        }

        if (!!width && width < breakpointLg) {
          return {
            slidesPerPage: 1,
            spacing: 24,
          }
        }

        return {
          slidesPerPage: 1,
          spacing: 24,
        }
    }
  }, [width, optionType])
}
