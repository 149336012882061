import { ChevronLeft, ChevronRight } from '@dreamstack/icons'
import type { KeenSliderInstance } from 'keen-slider'
import type { FunctionComponent, MutableRefObject } from 'react'
import styled from 'styled-components'
import type { TwStyle } from 'twin.macro';
import tw from 'twin.macro'
import type { KeenSliderOptionTypes } from '../index'

// TODO: Type styled component params.
const StyledChevron = styled(({ isLeft, onClick, optionType, ...props }) =>
  isLeft ? (
    <ChevronLeft onClick={onClick} {...props} />
  ) : (
    <ChevronRight onClick={onClick} {...props} />
  )
)<{
  disabled?: boolean
  isLeft: boolean
  optionType: KeenSliderOptionTypes
}>(({ disabled, isLeft, optionType }) =>
  chevronStyles(optionType, disabled, isLeft)
)

const chevronStyles = (
  optionType: KeenSliderOptionTypes,
  disabled?: boolean,
  isLeft?: boolean
) => {
  const defaultStyles = [
    tw`h-px-32 w-px-32 md:(h-px-64 w-px-64) cursor-pointer`,
    isLeft ? tw`left-8` : tw`right-8`,
    disabled ? tw`text-accentroGray-100` : tw`text-accentroGray-full`,
  ]
  let optionSpecificStyles: (TwStyle | null)[] = []
  switch (optionType) {
    case 'PropImageSlider': {
      optionSpecificStyles = [
        !disabled ? tw`text-accentroWhite-full` : tw`text-accentroWhite-200`,
        isLeft
          ? tw`absolute -left-px-16 md:(-left-px-32) top[calc(50% - 32px)] -translate-x-full`
          : null,
        !isLeft
          ? tw`absolute -right-px-16 md:(-right-px-32) top[calc(50% - 32px)] translate-x-full`
          : null,
      ]
      break
    }
    case 'CurrentPressArticles':
    case 'WorkingForAccentro': {
      optionSpecificStyles = [tw`ml:(h-px-80 w-px-80)`]
      break
    }
  }

  return [...defaultStyles, ...optionSpecificStyles]
}

export const SliderChevron: FunctionComponent<React.PropsWithChildren<{
  slider: MutableRefObject<KeenSliderInstance | null>
  disabled?: boolean
  isLeft?: boolean
  optionType: KeenSliderOptionTypes
}>> = ({ slider, disabled, isLeft, optionType }) => {
  return (
    <StyledChevron
      optionType={optionType}
      onClick={(e: Event) => {
        e.stopPropagation()
        isLeft ? slider.current?.prev() : slider.current?.next()
      }}
      isLeft={isLeft}
      disabled={disabled}
    />
  )
}
