import type { KeenSliderInstance } from 'keen-slider'
import range from 'lodash/range'
import type { FunctionComponent, MouseEvent, MutableRefObject } from 'react'
import tw, { styled } from 'twin.macro'
import type { KeenSliderOptionTypes } from '../index'

const StyledSliderBar = styled.button<{
  active?: boolean
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  optionType: KeenSliderOptionTypes
}>(({ active, optionType }) => [
  tw` w-full rounded-none md:h-px-8 `,
  optionType === 'CurrentPressArticles' ||
  optionType === 'PropImageSlider' ||
  optionType === 'WorkingForAccentro' ||
  optionType === 'RecommendedFlats'
    ? tw`h-px-6`
    : tw`h-px-4 `,
  active ? tw`bg-accentroGray-full` : tw`bg-accentroGray-100`,
])

const SliderBarContainer = styled.div<{
  optionType: KeenSliderOptionTypes
}>(({ optionType }) => [
  tw`flex justify-center `,
  (optionType === 'CurrentPressArticles' ||
    optionType === 'WorkingForAccentro') &&
    tw`mr-px-40 lg:mr-px-64 space-x-px-16 w-full`,
  optionType === 'PropImageSlider' && tw`mr-px-40 lg:mr-px-80`,
  (optionType === 'MoreFlats' || optionType === 'RecommendedFlats') &&
    tw`w-full`,
])

export const SliderBottomNavigation: FunctionComponent<React.PropsWithChildren<{
  slider: MutableRefObject<KeenSliderInstance | null>
  optionType: KeenSliderOptionTypes
  slidesPerView: number
  slideIndex: number
  isArticle?: boolean
}>> = ({ slider, slidesPerView, slideIndex, optionType }) => {
  const sliderKeys =
    slider && slider.current
      ? range(slider.current.track.details?.slides.length)
      : []

  return (
    <SliderBarContainer optionType={optionType}>
      {sliderKeys.map((idx) => {
        if (idx > Math.ceil(sliderKeys.length - slidesPerView)) return null

        return (
          <StyledSliderBar
            optionType={optionType}
            active={slideIndex === idx}
            key={idx}
            onClick={() => {
              slider.current?.moveToIdx(idx)
            }}
          />
        )
      })}
    </SliderBarContainer>
  )
}
