import type { KeenSliderOptions } from 'keen-slider'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import type { FunctionComponent, LegacyRef } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import type { KeenSliderOptionTypes } from '../index'
import { GridWithGaps, useResponsiveKeenSliderOptions } from '../index'
import { AccentroSlides } from './AccentroSlide'
import { SliderBottomNavigation } from './SliderBottomNavigation'
import { SliderBottomNavigationWithPreview } from './SliderBottomNavigationWithPreview'
import { SliderChevron } from './SliderChevrons'

export const BottomNavigationWrapper = styled.div<{
  optionType: KeenSliderOptionTypes
}>(({ optionType }) => [
  tw`z-10 flex items-center col-span-12 mt-px-32 ml:(mt-px-48)`,
  optionType === 'MoreFlats' && tw`ml:(mt-px-32)`,
  optionType === 'RecommendedFlats' &&
    tw`mt-px-64 mr-px-24 md:(margin-top[88px] mr-px-48) ml:(margin-right[91px])`,
  optionType === 'PropImageSlider' && tw`mt-px-32 ml:(mt-px-24)`,
  (optionType === 'CurrentPressArticles' ||
    optionType === 'WorkingForAccentro') &&
    tw`mt-px-40 md:(col-span-6 mt-px-48) lg:(col-span-5)`,
])

export const AccentroSliderWrapper = styled.div<{
  optionType: KeenSliderOptionTypes
}>(({ optionType }) => [
  tw`col-span-full relative`,
  (optionType === 'PropImageSlider' ||
    optionType === 'BlockImageGallerySmall' ||
    optionType === 'BlockImageGalleryBig') &&
    tw`col-span-full`,
])

export const SliderChevronWrapper = styled.div<{
  optionType: KeenSliderOptionTypes
}>(({ optionType }) => [
  tw`flex justify-end`,
  !(optionType === 'PropImageSlider') && tw`ml-px-32 md:(ml-px-48)`,
])

export const Wrapper = styled.div<{ optionType: KeenSliderOptionTypes }>(
  ({ optionType }) => [
    optionType === 'PropImageSlider' &&
      // tw`width[312px] sm:width[624px] ml:width[1150px]`,
      tw`width[75%] margin[auto] `,
  ]
)

export type AccentroSliderProps = {
  slides: Slide[]
  thumbnails?: Slide[]
  videos?: Slide[]
  hasVideo?: boolean
  width?: number
  onClick?: () => void
  currentSlide?: number
  optionType: KeenSliderOptionTypes
  slidesPerView?: number
  spacing?: number
  withSelection?: boolean
  useBottomNavigation?: boolean
  onSlideChange?: (slide: number) => void
}

export type SlideType = 'picture' | 'video' | 'lexicon'

export type Slide = {
  title?: string | null
  slide: JSX.Element
  type?: SlideType
  active?: boolean
  hasContent?: boolean
}

export const AccentroSlider: FunctionComponent<
  React.PropsWithChildren<AccentroSliderProps>
> = ({
  slides,
  videos,
  width,
  onClick,
  currentSlide = 0,
  useBottomNavigation,
  optionType,
  thumbnails,
}) => {
  const [slideIndex, setSlideIndex] = useState(currentSlide)

  const [pause, setPause] = useState(false)
  const hasVideo = !!videos
  const timer = useRef<ReturnType<typeof setInterval>>()
  const {
    slidesPerPage,
    spacing,
    height,
    duration,
    autoSlide,
    freezeDuration,
  } = useResponsiveKeenSliderOptions(optionType)
  const sliderOptions = useMemo(() => {
    return {
      initial: currentSlide,
      mode: 'snap',
      rtl: false,
      loop: !!autoSlide,
      slides: {
        perView: slidesPerPage,
        spacing: spacing,
      },
      duration: duration || 1000,
      freezeDuration: freezeDuration || 5000,
      slideChanged: (slider) => {
        setSlideIndex(slider.track.details.rel)
      },
    } as KeenSliderOptions & { freezeDuration?: number }
  }, [
    currentSlide,
    autoSlide,
    slidesPerPage,
    spacing,
    duration,
    freezeDuration,
  ])

  const [sliderRef, slider] = useKeenSlider(sliderOptions)
  const slideDuration = sliderOptions.freezeDuration

  useEffect(() => {
    slider?.current?.moveToIdx(currentSlide)
  }, [currentSlide, slider])

  const sliderSize =
    slider && slider.current ? slider.current.track.details?.slides.length : 0
  const notEnoughSlides = sliderSize <= Math.round(slidesPerPage)

  useEffect(() => {
    if (autoSlide) {
      slider.current?.container.addEventListener('mouseover', () => {
        setPause(true)
      })
      slider.current?.container.addEventListener('mouseout', () => {
        setPause(false)
      })
    }
  }, [slider, autoSlide])

  useEffect(() => {
    slider.current?.update()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides?.length])

  useEffect(() => {
    if (autoSlide) {
      timer.current = setInterval(() => {
        if (!pause && slider) {
          slider.current?.next()
        }
      }, slideDuration)
      return () => {
        if (timer.current) clearInterval(timer.current)
      }
    }
  }, [pause, slider, slideDuration, autoSlide])

  return (
    <Wrapper optionType={optionType}>
      <GridWithGaps>
        <AccentroSliderWrapper optionType={optionType}>
          <div tw="w-full ">
            <div
              ref={sliderRef as LegacyRef<HTMLDivElement>}
              className="keen-slider"
            >
              {hasVideo && <AccentroSlides slides={videos} />}

              <AccentroSlides
                height={height}
                width={width}
                slides={slides}
                onClick={onClick}
              />
            </div>
          </div>
          {optionType === 'PropImageSlider' && !!slider && (
            <>
              <SliderChevron
                slider={slider}
                disabled={!autoSlide && slideIndex === 0}
                isLeft
                optionType={optionType}
              />
              <SliderChevron
                optionType={optionType}
                slider={slider}
                disabled={
                  !autoSlide &&
                  slideIndex === Math.ceil(sliderSize - slidesPerPage)
                }
              />
            </>
          )}
        </AccentroSliderWrapper>
        {!notEnoughSlides &&
          (optionType !== 'PropImageSlider' ? (
            <BottomNavigationWrapper optionType={optionType}>
              <div tw="w-full">
                {useBottomNavigation && (
                  <SliderBottomNavigation
                    optionType={optionType}
                    slideIndex={slideIndex}
                    slider={slider}
                    slidesPerView={slidesPerPage}
                  />
                )}
              </div>
              <SliderChevronWrapper optionType={optionType}>
                <SliderChevron
                  slider={slider}
                  disabled={!autoSlide && slideIndex === 0}
                  isLeft
                  optionType={optionType}
                />
                <SliderChevron
                  optionType={optionType}
                  slider={slider}
                  disabled={
                    !autoSlide &&
                    slideIndex === Math.ceil(sliderSize - slidesPerPage)
                  }
                />
              </SliderChevronWrapper>
            </BottomNavigationWrapper>
          ) : (
            <BottomNavigationWrapper optionType={optionType}>
              <div tw="w-full flex justify-center">
                {useBottomNavigation && (
                  <SliderBottomNavigationWithPreview
                    thumbnails={thumbnails}
                    optionType={optionType}
                    slideIndex={slideIndex}
                    slider={slider}
                    slidesPerView={slidesPerPage}
                  />
                )}
              </div>
            </BottomNavigationWrapper>
          ))}
      </GridWithGaps>
    </Wrapper>
  )
}
