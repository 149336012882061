import type { KeenSliderInstance } from 'keen-slider'
import range from 'lodash/range'
import type {
  FunctionComponent,
  MouseEvent,
  MutableRefObject} from 'react';
import {
  useEffect,
  useRef,
} from 'react'
import tw, { css, styled } from 'twin.macro'
import type { KeenSliderOptionTypes} from '../index';
import { useIsSmOrGreater } from '../index'
import type { Slide } from './AccentroSlider'

const StyledSliderBar = styled.button<{
  active?: boolean
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
  optionType: KeenSliderOptionTypes
}>(({ active }) => [
  tw`border-2 m-px-4 relative rounded-none h-px-80 w-px-80 flex-shrink-0 text-xs sm:(h-px-96 w-px-96 border-4)`,
  active ? tw`border-accentroWhite-full` : tw` border-transparent`,
])

const SliderBarContainer = styled.div(() => [
  css`
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  `,
  tw`flex justify-start flex-nowrap overflow-auto`,
])

export const SliderBottomNavigationWithPreview: FunctionComponent<React.PropsWithChildren<{
  thumbnails?: Slide[]
  slider: MutableRefObject<KeenSliderInstance | null>
  optionType: KeenSliderOptionTypes
  slidesPerView: number
  slideIndex: number
  isArticle?: boolean
}>> = ({ slider, slidesPerView, slideIndex, optionType, thumbnails }) => {
  const sliderKeys =
    slider && slider.current
      ? range(slider.current.track.details.slides.length)
      : []

  const containerRef = useRef<HTMLDivElement | null>(null)
  const isSmOrGreater = useIsSmOrGreater()
  const scrollToRef = () => {
    if (!containerRef.current) return

    const offset = isSmOrGreater ? 96 : 48
    //scroll into view does not work here because it also scrolls vertically which we do not want and its not able to disable it
    const containerRect = containerRef.current.getBoundingClientRect()
    // calc the width of each thumbnail - 96 is the offset which gets added by the grid and scrollwidth does not respect that its not part of its container ._.
    const unitWidth =
      (containerRef.current.scrollWidth - offset) / sliderKeys.length

    //calc the offset to the center of box
    const center = (containerRect.width - offset) / 2 + unitWidth / 2
    // console.log({
    //   unitWidth,
    //   sw: containerRef.current.scrollWidth,
    //   keyslength: sliderKeys.length,
    //   left: unitWidth * (slideIndex + 1) - center,
    //   center,
    //   containerRect,
    // })

    //scroll left the amount of the units times their width, then minus the center offset so it scrolls the current thumbnail into center of box
    containerRef.current.scrollTo({
      behavior: 'smooth',
      left: unitWidth * (slideIndex + 1) - center,
    })
    // console.log({ center, unitWidth, slideIndex })
  }
  useEffect(() => {
    scrollToRef()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideIndex])
  return (
    <SliderBarContainer ref={containerRef}>
      {sliderKeys.map((idx) => {
        if (idx >= sliderKeys.length - Math.round(slidesPerView) + 1)
          return null
        const active = slideIndex === idx
        return (
          <StyledSliderBar
            optionType={optionType}
            key={idx}
            active={active}
            onClick={() => {
              slider.current?.moveToIdx(idx)
            }}
          >
            {thumbnails?.[idx].slide}
          </StyledSliderBar>
        )
      })}
    </SliderBarContainer>
  )
}
