import type { FunctionComponent } from 'react';
import { Fragment } from 'react'
import 'twin.macro'
import type { Slide } from './AccentroSlider'

export type AccentroSlideProps = {
  onClick?: () => void
  height?: number

  width?: number
  slides: Slide[]
}

export const AccentroSlides: FunctionComponent<React.PropsWithChildren<AccentroSlideProps>> = ({
  slides,
}) => {
  return (
    <>
      {slides.map((slide, index) => {
        return (
          <Fragment key={index}>
            <div className={'keen-slider__slide'}>
              <div aria-hidden tw="flex flex-col justify-center h-full">
                {slide.slide}

                {slide.title && (
                  <div tw="h-px-20 mt-px-24 text-px-16 leading-px-24 text-center text-accentroWhite-full">
                    {slide.title}
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )
      })}
    </>
  )
}
